import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  page: {
    pageTitle: ".",
    logoUrl: "https://bookingbash.com/images/logo-white.png"
  },
  metadata: {
    favicon: "https://bookingbash.com/images/favicon-2.png?v=10",
    title: "Booking Bash API Documentation",
    authors: ["Ashik Basheer"]
  },
  topNavigation: [
    { id: "api", label: "API Reference" },
  ],
  redirects: [
    { from: "/", to: "/api" },
  ],
  apis: {
    type: "file",
    input: "./apis/openapi.yaml",
    navigationId: "api",
  },
};

export default config;
